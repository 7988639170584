<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('daily_login.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('daily_login.head3') }} </v-card-title>

        <v-form ref="form" v-model="formValid" @submit.prevent="submitForm">
          <v-divider class="mx-3" />
          <!-- Title Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-row class="ma-0">
                <v-col cols="12" xs="6" md="6" xl="6">
                  <v-text-field v-model="formData.title" :label="$t('daily_login.header2')" outlined dense
                    disabled></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.month" :items="months" item-text="name" item-value="value"
                    :label="$t('daily_login.create_month')" outlined dense disabled></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.year" :items="years" :label="$t('daily_login.create_year')" outlined dense
                    disabled></v-select>
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>

          <!-- Bonus type and amount Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-col class="pa-0">
                <v-card-title class="pb-2 px-3">{{ $t('daily_login.create_bonus') }}</v-card-title>
                <v-card-text class="px-3">{{ $t('daily_login.create_bonus_detail') }}</v-card-text>
              </v-col>
              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-select v-model="formData.bonusType" :items="rewardTypes"
                    :label="$t('daily_login.create_reward_type')" outlined dense disabled></v-select>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.bonusAmount" :label="$t('daily_login.create_reward_amount')"
                    type="number" outlined dense disabled></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>

          <!-- Daily List -->
          <v-card-actions v-if="formData.month && formData.year" class="d-flex flex-column pa-2">
            <v-col class="pa-0">
              <v-card-title class="pb-2 px-3">{{ $t('daily_login.reward_head') }}</v-card-title>
              <v-card-text class="px-3">{{ $t('daily_login.reward_head_detail') }}</v-card-text>
            </v-col>

            <v-row v-for="(day, index) of days" :key="index" class="pa-2" style="width: 100%">
              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-row>
                    <v-col cols="4" xs="4" md="4" xl="4">
                      <p class="mt-2">{{ `Day ${index + 1}` }}</p>
                    </v-col>
                    <v-col cols="8" xs="8" md="8" xl="8">
                      <v-select v-model="day.type" :items="rewardTypes" :label="$t('daily_login.create_reward_type')"
                        outlined dense disabled></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="day.amount" :label="$t('daily_login.create_reward_amount')" outlined dense
                    disabled></v-text-field>
                </v-col>
                <v-col cols="3" class="pa-0">
                  <!-- <v-btn v-if="index === 0 && formData.month && formData.year" @click="useAll" color="warning"
                      >ใช้รูปแบบนี้ทั้งหมด</v-btn
                    > -->
                </v-col>
              </v-row>
            </v-row>
          </v-card-actions>
        </v-form>

        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="$router.push({ name: 'daily-login' })">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="px-3">{{ $t('back') }}</span>
          </v-btn>
          <!-- <v-btn color="success" class="px-3" @click="submitForm" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">สร้าง</span></v-btn
            > -->
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      loading: false,
      formValid: true,
      formRule: {
        // username: [
        //   value => !!value || 'username is required.',
        //   value => (!!value && value.length > 5) || 'need more than 5 characters',
        //   value => (!!value && value.indexOf(' ') < 0) || 'must have no spaces',
        //   value => (!!value && !value.toLowerCase().includes('auto')) || 'must not have string "auto"',
        //   value => (!!value && this.checkNotPhoneNumber(value)) || 'must not have phone number',
        // ],
        // password: [
        //   value => !!value || 'password is required.',
        //   value => (!!value && value.length > 5) || 'need more than 5 characters',
        // ],
        // phone: [
        //   value => !!value || 'phone is required.',
        //   value => (!!value && value.length === 10) || 'need equal 10 characters',
        // ],
        // email: [
        //   value => !!value || 'email is required.',
        //   v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'email must be valid',
        // ],
        // role_id: [value => !!value || 'role is required.'],
      },
      formData: {
        title: '',
        month: null,
        year: null,
        bonusType: 'point',
        bonusAmount: 1000,
        maxDay: null,
        days: [],
      },
      months: [
        { value: 1, name: this.$t('month1') },
        { value: 2, name: this.$t('month2') },
        { value: 3, name: this.$t('month3') },
        { value: 4, name: this.$t('month4') },
        { value: 5, name: this.$t('month5') },
        { value: 6, name: this.$t('month6') },
        { value: 7, name: this.$t('month7') },
        { value: 8, name: this.$t('month8') },
        { value: 9, name: this.$t('month9') },
        { value: 10, name: this.$t('month10') },
        { value: 11, name: this.$t('month11') },
        { value: 12, name: this.$t('month12') },
      ],
      years: [],
      rewardTypes: ['point', 'coin'],

      breadcrumbsItems: [
        {
          text: this.$t('daily_login.head'),
          disabled: false,
          href: '/dailyLogin',
        },
        {
          text: this.$t('daily_login.head3'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
      dailyData: {},
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }

    this.getDailyLoginRewardData()

    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()
    this.formData.title = `ประจำเดือน ${this.getMonthName(currentMonth)} ${currentYear}`

    for (let i = 0; i < 10; i++) {
      this.years.push(currentYear + i)
    }
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'Daily Login',
            url: window.location.href,
            detail: 'รายละเอียด Daily Login',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getDailyLoginRewardData() {
      const id = this.$route.params.id
      this.loading = true
      try {
        let res = await this.$store.dispatch('getDailyLoginReward', `id=${id}`)
        this.dailyData = res
        let resArr = []
        for (let i = 0; i < this.dailyData.data.length; i++) {
          resArr.push({ type: this.dailyData.data[i].type, amount: this.dailyData.data[i].amount })
        }

        this.formData = {
          title: this.dailyData.daily_data.title,
          month: this.dailyData.daily_data.month,
          year: this.dailyData.daily_data.year,
          bonusType: this.dailyData.daily_data.type_bonus,
          bonusAmount: this.dailyData.daily_data.amount_bonus,
          maxDay: this.dailyData.daily_data.max_day,
          days: resArr,
        }
        this.loading = false
      } catch (e) { }
    },

    useAll() {
      let firstDayData = this.formData.days[0]
      let daysLength = this.formData.days.length
      let updatedDays = []
      for (let i = 0; i < daysLength; i++) {
        updatedDays.push({ type: firstDayData.type, amount: firstDayData.amount })
      }

      this.$set(this.formData, 'days', updatedDays)
    },
    submitForm() {
      // this.updateDailyLoginData()
    },
    async updateDailyLoginData() {
      for (let i = 0; i < this.formData.days.length; i++) {
        this.formData.days[i].amount = parseInt(this.formData.days[i].amount)
      }

      try {
        this.loading = true
        let res = await this.$store.dispatch('addDailyLogin', this.formData)

        if (res.status === 'success') {
          this.$swal.fire(`${this.$t('daily_login.edit_success1')} ${this.formData.title} ${this.$t('daily_login.edit_success2')}`, '', 'success')
        } else if (res.code === 400) {
          this.$swal.fire(
            `${this.$t('daily_login.edit_fail1_1')} ${this.formData.title} ${this.$t('daily_login.edit_fail1_2')} `,
            '',
            'error',
          )
        } else {
          this.$swal.fire(this.$t('daily_login.edit_fail2'), '', 'error')
        }
        this.$refs.form.reset()
        this.loading = false
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e,
          })
        }
      }
    },
    getMonthName(monthValue) {
      const month = this.months.find(m => m.value === monthValue)
      return month ? month.name : ''
    },
    getDaysInMonth(month, year) {
      return new Date(year, month, 0).getDate()
    },
    setDailyList() {
      const daysInMonth = new Date(this.formData.year, this.formData.month, 0).getDate()
      const currentMonth = new Date(this.formData.year, this.formData.month, 0).getMonth() + 1
      const currentYear = new Date(this.formData.year, this.formData.month, 0).getFullYear()

      let resArr = []
      for (let i = 0; i < daysInMonth; i++) {
        resArr.push({ type: null, amount: null })
      }

      this.$set(this.formData, 'title', `ประจำเดือน ${this.getMonthName(currentMonth)} ${currentYear}`)
      this.$set(this.formData, 'maxDay', daysInMonth)
      this.$set(this.formData, 'days', resArr)
    },
  },
  computed: {
    days() {
      if (this.formData.days.length === 0 && this.formData.month !== null && this.formData.year !== null) {
        this.setDailyList()
      } else {
        return this.formData.days
      }
    },
  },
  watch: {
    // 'formData.month': function (newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     this.setDailyList()
    //   }
    // },
    // 'formData.year': function (newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     this.setDailyList()
    //   }
    // },
  },
}
</script>
